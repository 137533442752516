import React, { useMemo } from 'react';
import { ContentAST_images } from '../../graphql-generated';
import { getPathName } from '../../utils/getPathName';
import { ImageContext } from '../../contexts';

// Maps images from /wp-content/{{year}}/{{month}}/{{image}}.jpg to
// the version stored in Gatsby.
export const ImagesProvider: React.FC<{
  images: ContentAST_images[];
}> = ({ images, children }) => {
  const value = useMemo(
    () => new Map(images.map(img => [getPathName(img.url), img] as const)),
    [images]
  );
  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
};
