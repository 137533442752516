import classNames from 'classnames';
import React from 'react';
import './input.scss';

export const Input = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(({ className, ...props }, ref) => {
  return (
    <input
      {...props}
      className={classNames('input', className)}
      ref={ref as any}
    />
  );
});

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
>(({ className, ...props }, ref) => {
  return (
    <textarea
      {...props}
      ref={ref as any}
      className={classNames('input', 'input--textarea', className)}
    />
  );
});

export const InputWrapper = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
>((props, ref) => <div ref={ref} {...props} className="input-wrapper" />);

export const InputLabel = React.forwardRef<
  HTMLLabelElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
>((props, ref) => <label ref={ref} {...props} className="input-label" />);
