/**
 * TODO: Clean up this file and extract multiple components.
 */

import React, { useMemo } from 'react';
import rehype2react from 'rehype-react';
import unified from 'unified';
import { ReactComponent as Spinner } from '../../assets/icons/tail-spin.svg';
import { ContentAST_images } from '../../graphql-generated';
import { ASTImg } from '../atoms/astImg';
import { Button, ButtonType } from '../atoms/button';
import { ImagesProvider } from '../atoms/imagesProvider';
import { Input, InputLabel, InputWrapper, Textarea } from '../atoms/input';
import { NetlifyForm, useNetlifyForm } from '../atoms/netlifyForm/netlifyForm';
import { Select } from '../atoms/select/select';
import { AdLink } from './adLink';

export const ASTRenderer: React.FC<{
  ast?: any;
  images: ContentAST_images[];
}> = ({ ast, images }) => {
  const rendered = useRenderedAst(ast);
  return (
    <div className="wysiwyg-content">
      <ImagesProvider images={images}>{rendered}</ImagesProvider>
    </div>
  );
};

const ASTListItem = (props: any) => {
  return <li {...props} />;
};

type ASTInputType = {
  type: string;
  name: string;
  id?: string;
  value?: string;
  autoComplete?: string;
  required?: boolean;
  rows?: number;
  'data-provide'?: string;
  'data-date-format'?: string;
  'data-date-language'?: string;
  'data-date-start-view'?: string;
  'data-date-start-date'?: string;
  'data-date-end-date'?: string;
  'data-field'?: string;
};

const LoadingSubmitButton = ({
  loading,
  ...props
}: ASTInputType & { loading: boolean }) => {
  return (
    <Button {...props} type={ButtonType.Blue}>
      {loading && (
        <Spinner
          style={{
            marginRight: '1rem',
            height: '1.5em',
            width: '1.5em',
          }}
        />
      )}{' '}
      {props.value}
    </Button>
  );
};

const ASTInput = (props: ASTInputType) => {
  const { loading } = useNetlifyForm();
  if (props.type === 'submit') {
    return <LoadingSubmitButton {...props} loading={loading} />;
  }

  const key = props.name;

  const shouldDeleteKey =
    key.startsWith('_cf_') || // Caldera form hidden field
    key === '_wp_http_referer' || // Referer for the form. This is always the API.
    key === 'cfajax' || // Key used for ajax requests in caldera forms
    key.startsWith('fld_'); // Generic fields that shouldn't be posted to Netlify. Buttons etc.

  if (shouldDeleteKey) return null;

  // rows always exists on Textarea.
  if (typeof props.rows !== 'undefined') {
    return (
      <Textarea
        defaultValue={props.value}
        required={props.required}
        name={props.name}
        id={props.id}
        rows={props.rows}
        disabled={loading}
      />
    );
  }

  if (props['data-provide'] === 'cfdatepicker') {
    const { value, ...rest } = props;

    return (
      <Input {...rest} type="date" disabled={loading} defaultValue={value} />
    );
  }

  if (props.type === 'radio') {
    return <input {...props} disabled={loading} />;
  }

  if (props.type === 'checkbox') {
    return <input {...props} disabled={loading} />;
  }

  return (
    <Input
      type={props.type}
      defaultValue={props.value}
      required={props.required}
      name={props.name}
      id={props.id}
      disabled={loading}
    />
  );
};

const ASTSelect = ({ value, ...props }: any) => {
  const { loading } = useNetlifyForm();
  return <Select defaultValue={props.value} {...props} disabled={loading} />;
};

const ASTDiv = (props: any) => {
  if (typeof props['data-field-wrapper'] !== 'undefined') {
    return <InputWrapper {...props} />;
  }

  if (props.className === 'hide') return null; // Removes caldera honey pot
  if (props.className === 'caldera-grid') return props.children;
  if (props.id?.startsWith('caldera_notices')) return null;

  return <div {...props} />;
};

const ASTAnchor = (props: any) => {
  if (props.rel?.includes('nofollow') && props.rel?.includes('sponsored')) {
    return <AdLink {...props} />
  }

  return <a {...props}>{ props.children }</a>;
}

const ASTp = (props: any) => {
  if (props.children?.length === 1 && props.children[0]?.type?.name === 'ASTAnchor') {
    return <div {...props}>
      { props.children }
    </div>
  }

  return <p {...props}>{ props.children }</p>
}

/**
 * AST stands for Abstract Syntax Tree (https://en.wikipedia.org/wiki/Abstract_syntax_tree)
 * This is where the magic happens.
 * This translates the HAST (HTML AST) into React components.
 *
 * For rendering specific components for some tags, use `tagName: MyComponent`,
 * e.g. ul: List
 */
const renderer = unified().use(rehype2react, {
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: {
    img: ASTImg,
    li: ASTListItem,
    input: ASTInput,
    textarea: ASTInput,
    label: InputLabel,
    div: ASTDiv,
    form: NetlifyForm,
    select: ASTSelect,
    a: ASTAnchor,
    p: ASTp,
  },
});

const useRenderedAst = (ast: any): string => {
  const rendered = useMemo(() => renderer.stringify(ast), [ast]);
  return rendered;
};
