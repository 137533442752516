import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import './adLink.scss';

export type AdLinkProps = {
  children: any[];
  href: string;
  rel: string;
  target: string;
};

export const AdLink: React.FC<AdLinkProps> = ({
  children,
  href,
  rel,
  target,
}) => {
  //see gatsby-node/utils/adjustSponsoredLinksInAst
  const [_,title,image] = (target || '').split("**");
  return (
    <div>
      <Link to={href} className="ad-link__link">
        <div className="ad-link__container">
          <div className="ad-link__header">
            {children}
          </div>
          <div className={classnames("ad-link__content", image && title && 'ad-link__content--spacings')}>
            {image &&
            <div className="ad-link__image" style={{ backgroundImage: `url(${image})` }}>
            </div>}
            {title &&
            <div className="ad-link__title">
              {title}
            </div>}
          </div>
        </div>
      </Link>    
    </div>
  );
};