import React from 'react';
import './select.scss';

export type SelectProps = {
  children?: React.ReactNode;
};

export const Select = (props: SelectProps) => {
  return (
    <div>
      <select {...props} className="select" />
    </div>
  );
};
