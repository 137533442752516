import Img from 'gatsby-image';
import React, { ReactChildren, useContext } from 'react';
import { ImageContext } from '../../contexts';
import { getPathName } from '../../utils/getPathName';

export type ASTImgProps = {
  src: string;
  children?: ReactChildren;
  style?: React.CSSProperties;
  className?: string;
};

export const ASTImg: React.FC<ASTImgProps> = ({ style, ...props }) => {
  const src = getPathName(props.src);
  const images = useContext(ImageContext);
  const image = images.get(src);

  if (image == null) return null;

  if (image?.childImageSharp?.fluid)
    return <Img fluid={image.childImageSharp.fluid as any} />;
  return (
    <img
      style={Object.assign({ maxWidth: '100%' }, style)}
      {...props}
      src={image?.publicURL || ''}
    />
  );
};
