export const getPathName = (path?: string | null) => {
  if (!path) return '';

  try {
    const url = new URL(path);

    return url.pathname;
  } catch (e) {
    return path ?? '';
  }
};
