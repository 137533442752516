import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, ButtonType } from '../button';
import './netlifyForm.scss';

export type NetlifyFormProps = {
  children?: React.ReactNode;
  className: string;
  'data-netlify': string;
  'data-instance': string;
  encType: string;
  id: string;
  method: string;
  name: string;
};

type NetlifyFormContextValue = {
  loading: boolean;
  success: boolean;
  error: Error | null;
};

const NetlifyFormContext = React.createContext<NetlifyFormContextValue>({
  loading: false,
  success: false,
  error: null,
});

export const useNetlifyForm = () => useContext(NetlifyFormContext);

export const NetlifyForm = ({
  className,
  children,
  ...props
}: NetlifyFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const successRefDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (success) {
      if (successRefDiv.current) {
        window.scrollTo({
          top: successRefDiv.current.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [success]);

  const submit = useCallback(
    async (ev: React.FormEvent) => {
      ev.preventDefault();
      if (isLoading) return;
      if (!formRef.current) return;
      const formData = new FormData(formRef.current);

      formData.append('form-name', formRef.current.name);

      setIsLoading(true);

      try {
        await fetch(formRef.current.action, {
          method: 'post',
          body: formData,
        });
        setSuccess(true);
      } catch (e) {
        setError(e);
      }

      setIsLoading(false);
    },
    [setIsLoading, isLoading]
  );

  const reset = useCallback(() => {
    window.location.reload();
  }, []);

  if (success) {
    return (
      <div ref={successRefDiv}>
        <h3>Tack!</h3>
        <Button type={ButtonType.Blue} onClick={reset}>
          Skicka in formuläret igen
        </Button>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h3>Något gick fel. Svaret har inte sparats.</h3>
        <Button type={ButtonType.Blue} onClick={reset}>
          Försök igen
        </Button>
      </div>
    );
  }

  return (
    <NetlifyFormContext.Provider
      value={{
        loading: isLoading,
        success,
        error,
      }}
    >
      <div>
        <form
          onSubmit={submit}
          ref={formRef}
          netlify-honeypot="this-is-my-very-cool-field-for-humans"
          {...props}
        >
          <div className="netlify-form-field">
            <input name="this-is-my-very-cool-field-for-humans" />
          </div>
          {children}
        </form>
      </div>
    </NetlifyFormContext.Provider>
  );
};
